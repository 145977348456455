import React from 'react'
import Typed from 'react-typed';

function Intro() {
  return (
    <div className='flex justify-lelft md:justify-center mt-2 md:mt-8 mb-12 bg-white px-4 text-6xl font-bold text-[#121212]'>
  <div className="max-w-[80%] text-left">
    Izinto. Getting everything On-Demand.
  </div>
</div>

  )
}

export default Intro