import React from 'react'

function Terms() {
  return (
    <div className='flex justify-lelft md:justify-center mt-2 md:mt-8 mb-12 bg-white px-4 text-6xl font-bold text-gray-800'>
  <div className="max-w-[80%] text-left">
    Our terms.
  </div>
</div>

  )
}

export default Terms